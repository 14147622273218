<template>
  <div class="online-consult">
    <use-sub-title :title="props.title" subTitle="人工在线咨询平台" line-width="160"></use-sub-title>
    <div class="content">
      <ul>
        <li v-for="(item, index) in navList" :key="index" @click="useCustomerService">
          <img :src="require(`@/assets/zhuanti/syb/${item.icon}`)" alt="">
          <p class="name">{{ item.name }}</p>
          <p class="EnglishName">{{ item.EnglishName }}</p>
          <span>点击查看</span>
        </li>
      </ul>
    </div>
    <!-- <use-button>立即报名</use-button> -->
  </div>
</template>

<script setup>
import { useCustomerService } from '@/api/common.js'

import { reactive } from "vue";

const props = defineProps(['title', 'subTitle']);
const navList = reactive([
  {
    icon: 'ico3.png',
    name: '报考条件',
    EnglishName: 'CONDITIONS',
  },
  {
    icon: 'ico4.png',
    name: '报考时间',
    EnglishName: 'TIME',
  },
  {
    icon: 'ico5.png',
    name: '报名入口',
    EnglishName: 'ENTRANCE',
  },
  {
    icon: 'ico6.png',
    name: '考试时间',
    EnglishName: 'EXAMINATION',
  },
  {
    icon: 'ico7.png',
    name: '考试科目',
    EnglishName: 'SUBJECT',
  },
  {
    icon: 'ico8.png',
    name: '报考流程',
    EnglishName: 'PROCESS',
  },
  {
    icon: 'ico14-1.png',
    name: '政策解析',
    EnglishName: 'ANALYSIS',
  },
  {
    icon: 'ico9.png',
    name: '高频考点',
    EnglishName: 'EXAMINATION',
  },
  {
    icon: 'ico10.png',
    name: '考试大纲',
    EnglishName: 'OUTLINE',
  },
  {
    icon: 'ico11.png',
    name: '历年真题',
    EnglishName: 'TRUE TOPIC',
  },
  {
    icon: 'ico12.png',
    name: '模拟题库',
    EnglishName: 'SIMULATION',
  },
  {
    icon: 'ico13.png',
    name: '课程指导',
    EnglishName: 'GUIDANCE',
  },
  {
    icon: 'ico14.png',
    name: '免费试听',
    EnglishName: 'ANDITION',
  },
  {
    icon: 'ico14-2.png',
    name: '新版辅导',
    EnglishName: 'NEW EDITION',
  },
]);
</script>

<style lang="scss" scoped>
.online-consult {
  width: 1200px;
  height: 680px;
  margin: 0 auto;

  .header {
    height: 150px;

    .header-top {
      height: 60px;
      display: flex;
      justify-content: center;

      img {
        width: 35px;
        height: 52px;
      }

      h2 {
        text-align: center;
        line-height: 52px;
        font-size: 38px;
        color: #2e2e2e;
        padding: 0 30px;
      }
    }

    .header-bottom {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      label {
        color: #686868;
        font-size: 18px;
        padding: 0 20px;
      }

      .drop {
        color: #cacaca;
        font-size: 36px;
        transform: translate(2px, -3px);
      }

      .drop2 {
        transform: translate(-2px, -3px);
      }

      .line {
        width: 190px;
        height: 1px;
        background: #cacaca;
      }
    }
  }

  .content {
    height: 425px;

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: 45px;

      li {
        margin-right: 70px;
        cursor: pointer;

        p {
          text-align: center;
        }

        img {
          transition: all 0.3s;
        }

        .name {
          font-size: 22px;
          font-weight: bold;

          cursor: pointer;
        }

        .EnglishName {
          font-size: 12px;
          color: #656565;
        }

        span {
          display: block;
          width: 82px;
          height: 25px;
          margin: 12px auto 50px auto;
          text-align: center;
          line-height: 25px;
          font-size: 14px;
          color: #e76c2d;
          border: solid 1px #e76c2d;
          border-radius: 50px;
          transition: all 0.3s;
        }
      }

      li:nth-child(7) {
        margin-right: 0;
      }

      li:nth-child(14) {
        margin-right: 0;
      }

      li:hover {
        .name {
          color: #e76c2d;
        }

        img {
          transform: translateY(-8px);
        }

        span {
          background: #e76c2d;
          color: white;
        }
      }
    }
  }
}
</style>