<template>
    <div class="get-past">
        <use-sub-title title="高效备考 一次上岸" subTitle="精准课程 提分看得见"></use-sub-title>
        <div class="text-body">
            <ul class="text-ul">
                <li v-for="(item, index) in textList" :key="index">
                    <div v-show="index < 3" class="nice">
                        <div class="content">
                            <h3>{{ item.title }}</h3>
                            <p>{{ item.content }}</p>
                        </div>
                        <div class="done">
                            {{ item.num }}
                        </div>
                    </div>
                    <div v-show="index > 2" class="nice">
                        <div class="done">
                            {{ item.num }}
                        </div>
                        <div class="content">
                            <h3>{{ item.title }}</h3>
                            <p>{{ item.content }}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="center-body">
        </div>
        <div class="bottom-body">

        </div>
    </div>
</template>

<script setup>
import { reactive } from "vue";

const textList = reactive([
    {
        num: '03',
        title: '更适合考试特点的课程体系',
        content: '根据考试特点，学员实际情况制定的专属课程，重要考点多层次反复训练，确保考试通过'
    },
    {
        num: '02',
        title: '独家内部资料代替教材',
        content: '按章节编排，每节的考试分值命题方式，重要考点一应俱全学员学习更高效省时'
    },
    {
        num: '01',
        title: '根据考生特点针对性指导教学',
        content: '当前的CSE测评，测评当前基础'
    },
    {
        num: '04',
        title: '专属学管对接班主任',
        content: '分配班主任可以随时进行沟通答疑，督促你学习，给你发送各种考试资料和信息'
    },
    {
        num: '05',
        title: '考前两周核心密训资料助力通关',
        content: '从考前两周进入密训确保学员获得及时的考试相关信息和内容，通关考试'
    },
    {
        num: '06',
        title: '签订法律协议未过第二年免费重学',
        content: '无论学员任何原因没有通过次年都可以申请免费重学，所有资料和课程无任何费用'
    },
]);
</script>

<style lang="scss" scoped>
.get-past {
    position: relative;
    height: 585px;
    border-top: 1px solid #e4e4e4;
    .text-body {
        width: 1200px;
        height: 322px;
        margin: 0 auto;
        margin-top: -10px;
        background: url('@/assets/zhuanti/line6.png') no-repeat 50.7% 0px;

        .text-ul {
            position: relative;

            li {
                position: absolute;

                .nice {
                    display: flex;

                    .content {
                        h3 {
                            color: #333;
                            text-align: right;
                            width: 294px;
                        }

                        p {
                            font-size: 14px;
                            color: #585858;
                            text-align: right;
                            margin-top: 7px;
                            width: 294px;
                        }
                    }

                    .done {
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        background: #f16a5d;
                        border-radius: 50px;
                        text-align: center;
                        font-size: 24px;
                        color: #fff;
                        border: solid 5px #f9d1c0;
                        margin-left: 20px;
                    }
                }
            }

            li:nth-child(1) {
                top: 0px;
                left: 138px;
            }

            li:nth-child(2) {
                top: 105px;
                left: 75px;

                .content {
                    h3 {
                        width: 252px;
                    }

                    p {
                        width: 252px;
                    }
                }
            }

            li:nth-child(3) {
                top: 225px;
                left: 0px;
            }

            li:nth-child(4) {
                top: 0px;
                right: 83px;

                .content {
                    h3 {
                        width: 336px;
                        text-align: left;
                    }

                    p {
                        width: 336px;
                        text-align: left;
                    }
                }

                .done {
                    margin-right: 20px;
                }
            }

            li:nth-child(5) {
                top: 105px;
                right: 32px;

                .content {
                    h3 {
                        width: 280px;
                        text-align: left;
                    }

                    p {
                        width: 280px;
                        text-align: left;
                    }
                }

                .done {
                    margin-right: 20px;
                }
            }

            li:nth-child(6) {
                top: 225px;
                right: -15px;

                .content {
                    h3 {
                        width: 300px;
                        text-align: left;
                    }

                    p {
                        width: 270px;
                        text-align: left;
                    }
                }

                .done {
                    margin-right: 20px;
                }
            }

        }
    }

    .center-body {
        position: absolute;
        bottom: 50px;
        left: 50.5%;
        z-index: 2;
        transform: translateX(-50%);
        width: 396px;
        height: 295px;
        background: url('@/assets/zhuanti/dn.png') no-repeat;
        background-size: 100% 100%;
    }

    .bottom-body {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 145px;
        background: url('@/assets/zhuanti/bg_14.png') no-repeat;
    }
}</style>