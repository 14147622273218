<template>
  <div class="science_plan">
    <use-sub-title title="全程科学规划，上岸无忧" subTitle="科学规划 上岸无忧" lineWidth="130px"></use-sub-title>
    <ul class="plan_body">
        <li v-for="(item, index) in dataList" :key="index">
            <div class="top">
                <img :src="require(`@/assets/home/sciencePlan/${item.img_url}`)" alt="">
                <p class="title">
                    {{ item.title }}
                </p>
            </div>
            <p class="sub_title">
            {{ item.subTitle }}
            </p>
        </li>
    </ul>
  </div>
</template>

<script setup>
import { reactive } from 'vue';


const dataList = reactive([
  {
    title: '全程化的周到服务',
    subTitle: '老师全程陪伴，规划合理学习备考路径，助力学员快速上岸。',
    img_url: 'one.png'
  },
  {
    title: '多样化的课程选择',
    subTitle: '针对不同基础水平、学习时间、备考周期设计课程，满足不同备考人群需求。',
    img_url: 'two.png'
  },
  {
    title: '个性化的学习辅导',
    subTitle: '个性化辅导专家结合智能学习平台大数据，“学、练、测、评”与课程阶段，层层递进，因材施教。',
    img_url: 'three.png'
  },
])
</script>

<style lang="scss" scoped>
.science_plan {
    width: 100%;
    height: 640px;
    background: #ffffff;
    margin-top: 10px;
}
.plan_body {
    width: 65%;
    margin-left: 17.5%;
    display: flex;
    margin-top: 0px;
    justify-content: space-between;
    li {
        width: 28%;
        // height: 3.9rem;
        background: #ffffff;
        box-shadow: 0px 8px 24px 3px rgba(142, 142, 145, 0.18);
        padding: 10px 10px 50px 10px;
        .top {
            img {
                width: 100%;
            }
            .title {
                display: block;
                font-size: 24px;
                font-weight: bold;
                text-align: center;
                line-height: 45px;
            }
        }
        .sub_title {
            line-height: 30px;
            font-size: 20px;
        }
    }
}
</style>