<template>
    <div class="download-means">
        <use-sub-title :title="props.title" subTitle="“零” 基础备考必备 助力上岸" lineWidth="130px"></use-sub-title>
        <ul class="downloadList">
            <li v-for="(item, index) in downloadList" :key="index">
                <p class="title">
                    {{ item.title }}
                </p>
                <p v-for="(ele, indexs) in item.navList" :key="indexs" @click="useCustomerService" class="navList">
                    {{ ele }}
                    <span>点击查看</span>
                </p>
            </li>
        </ul>
        <!-- <use-button>获取更多资料</use-button> -->
    </div>
</template>

<script setup>
import { useCustomerService } from '@/api/common.js'
import { computed } from "vue";
import { useRoute } from 'vue-router';
import { download } from '@/api/download.js'

const props = defineProps({
    title: {
        default: '标题'
    }
});
const route = useRoute();
const downloadList = computed(() => {
    return download[route.name]
});

</script>

<style lang="scss" scoped>
.download-means {
    width: 1200px;
    height: 760px;
    margin: 0 auto;

    .downloadList {
        display: flex;
        flex-wrap: wrap;
        margin-top: -25px;
        li {
            width: 375px;
            height: 235px;
            margin: 12px;

            .title {
                width: 197px;
                height: 48px;
                font-size: 20px;
                color: #e76c2d;
                line-height: 39px;
                font-weight: bold;
                text-indent: 25px;
                margin-top: 10px;
                margin-left: -9px;
                background: url('@/assets/image/ico26.png') no-repeat;
                background-size: 197px 48px;
            }

            .navList {
                width: 300px;
                font-size: 14px;
                color: #fff;
                background: url('@/assets/image/ico27.png') no-repeat 20px center;
                padding-left: 48px;
                text-overflow: ellipsis;
                height: 33px;
                line-height: 33px;
                transition: all 0.3s;
                cursor: pointer;
                display: flex;
                justify-content: space-between;

                span {
                    display: block;
                    width: 60px;
                    text-align: center;
                    border-radius: 50px;
                    height: 20px;
                    line-height: 20px;
                    cursor: pointer;
                    font-size: 12px;
                    color: #f84b28;
                    margin-top: 4px;
                    transition: all 0.3s;
                    background: #fff;
                }
            }

            .navList:hover {
                color: #5547ed;

                span {
                    background: #5547ed;
                    color: #fff
                }

                ;
            }
        }

        li:nth-child(odd) {
            background: #f16a5d;
        }
        li:nth-child(even) {
            background: #f87b3b;
        }
    }
}</style>