<template>
    <div class="header" ref="header">
        <!-- :class="['header', offsetTop >]" -->
        {{ setTop }}
        <div class="header-top">
            <img :src="require(`@/assets/zhuanti/${imageUrl}`)" alt="">
            <h2 :style="{ color: titleIsWhite }">{{ props.title }}</h2>
            <img :src="require(`@/assets/zhuanti/${imageUrl}`)" alt="">
        </div>
        <div class="header-bottom" v-if="props.isSubTitle">
            <span class="drop" :style="{ color: subIsWhite }">·</span>
            <span class="line" :style="{ width: props.lineWidth, background: subIsWhite }"></span>
            <label :style="{ color: subIsWhite }">{{ props.subTitle }}</label>
            <span class="line" :style="{ width: props.lineWidth, background: subIsWhite }"></span>
            <span class="drop drop2" :style="{ color: subIsWhite }">·</span>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
    title: {
        default: '标题',
    },
    subTitle: {
        default: '二级标题'
    },
    is_white: {
        default: false
    },
    lineWidth: {
        default: '50px'
    },
    isSubTitle: {
        default: true
    }
});
const imageUrl = computed(() => {
    return props.is_white ? 'ico15.png' : 'ico1.png'
});

const titleIsWhite = computed(() => {
    return props.is_white ? 'white' : '#2e2e2e'
});

const subIsWhite = computed(() => {
    return props.is_white ? 'white' : '#686868'
});
</script>

<style lang="scss" scoped>
@keyframes title-animate {
    0% {
        padding-top: 80px;
    }

    100% {
        padding-top: 40px;
    }
}


.header {
    padding-top: 40px;
    min-height: 150px;
    animation: title-animate 0.8s;
    .header-top {
        height: 60px;
        display: flex;
        justify-content: center;

        img {
            width: 35px;
            height: 52px;
        }

        h2 {
            text-align: center;
            line-height: 52px;
            font-size: 38px;
            color: #2e2e2e;
            padding: 0 30px;
        }
    }

    .header-bottom {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        label {
            font-size: 18px;
            padding: 0 20px;
        }

        .drop {
            font-size: 36px;
            transform: translate(2px, -3px);
        }

        .drop2 {
            transform: translate(-2px, -3px);
        }

        .line {
            width: 190px;
            height: 1px;
            background: #cacaca;
        }
    }
}</style>