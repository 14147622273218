<template>
  <div class="flowPath">
    <div class="center">
      <use-sub-title
        class="useSubTitle"
        title="报考流程"
        subTitle="任何考试问题 人工免费解答 了解实时讯息"
        :is_white="true"
      ></use-sub-title>
      <div class="body">
        <ul class="topList">
          <li
            @click="useCustomerService"
            v-for="(item, index) in topList"
            :key="index"
          >
            <p class="topTitle">
              {{ item.title }}
            </p>
            <p class="bottomTitle">
              {{ item.span }}
            </p>
            <p class="centerTitle">
              {{ item.title }}
            </p>
          </li>
        </ul>
        <ul class="bottomList">
          <li
            @click="useCustomerService"
            v-for="(item, index) in bottomList"
            :key="index"
          >
            <p class="centerTitle">
              {{ item.title }}
            </p>
            <p class="bottomTitle">
              {{ item.span }}
            </p>
            <p class="topTitle">
              {{ item.title }}
            </p>
          </li>
        </ul>
        <div class="rightBody" @click="useCustomerService">
          <p class="topTitle">首次报考学员重点须知</p>
          <ul class="centerTitle">
            <li>网上报名入口和时间</li>
            <li>网上报名操作流程</li>
            <li>报名资格审核具体内容</li>
            <li>报考时间是否充裕</li>
            <li>备考资料该怎么选择</li>
          </ul>
          <p class="bottomTitle">我要咨询</p>
        </div>
        <div class="lineBody">
          <span>·</span>
          <i class="line"></i>
          <span>·</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCustomerService } from "@/api/common.js";
import { computed, reactive } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const name = computed(() => {
  const CODE = {
    country: "国考公务员",
    province: "省考公务员",
    career: "事业编考试",
    // troops: "军队文职",
  };
  return CODE[route.name];
});
const topList = reactive([
  {
    title: "教务通知",
    span: "各省份教务通知时间，务必留意截止时间...",
  },
  {
    title: "考试时间",
    span: `2024${name.value}考试时间已公布，点击查询`,
  },
  {
    title: "打印网址",
    span: `2025年${name.value}考试打印网址一般采…`,
  },
  {
    title: "调剂",
    span: `${name.value}考试一般在考试结束后…`,
  },
]);
const bottomList = reactive([
  {
    title: "网上报名",
    span: "报名流程复杂考试流程预报名-登录..",
  },
  {
    title: "打印准考证",
    span: "报名成功即可打印准考证参加考试",
  },
  {
    title: "成绩查询",
    span: `2024年${name.value}考试成绩已公布，点击查…`,
  },
  {
    title: "面试",
    span: `${name.value}面试过程采取一对一对话...`,
  },
]);
</script>

<style lang="scss" scoped>
.flowPath {
  width: 1903px;
  height: 672px;
  background: url("@/assets/zhuanti/flowPathBgc.jpg") no-repeat;
  background-size: 100% 100%;

  .center {
    width: 1200px;
    height: 400px;
    margin: 0 auto;
    padding-top: 1px; // 处理盒子塌陷

    .body {
      position: relative;
      width: 1200px;
      height: 400px;

      .lineBody {
        position: absolute;
        left: 0;
        top: 192px;
        display: flex;
        align-items: center;
        z-index: 1;

        span {
          width: 15px;
          height: 15px;
          background: #fff;
          display: block;
          border-radius: 50%;
        }

        .line {
          display: block;
          width: 1170px;
          height: 1px;
          background-color: #fff;
        }
      }

      .topList,
      .bottomList {
        display: flex;
        position: inherit;
        z-index: 2;
        li:first-child {
          margin-left: 20px;
        }

        li {
          width: 193px;
          height: 220px;
          margin-right: 47px;
          cursor: pointer;

          .topTitle {
            font-size: 18px;
            color: #e76c2d;
            cursor: pointer;
            font-weight: bold;
          }

          .bottomTitle {
            width: 150px;
            font-size: 14px;
            color: #fff;
            line-height: 22px;
            cursor: pointer;
          }

          .centerTitle {
            font-size: 14px;
            color: #e41539;
            cursor: pointer;
            font-weight: bold;
            text-align: center;
          }
        }
      }

      .topList {
        li {
          background: url("@/assets/zhuanti/flowPathTop.png") no-repeat;
        }

        .topTitle {
          margin-top: 7px;
          margin-left: 25px;
        }

        .bottomTitle {
          margin-left: 30px;
          margin-top: 17px;
        }

        .centerTitle {
          margin-top: 97px;
        }
      }

      .bottomList {
        margin-left: 119px;
        transform: translateY(-39px);

        li {
          background: url("@/assets/zhuanti/flowPathBottom.png") no-repeat;
        }

        .topTitle {
          margin-top: 18px;
          margin-left: 25px;
        }

        .bottomTitle {
          margin-left: 30px;
          margin-top: 97px;
        }

        .centerTitle {
          margin-top: 8px;
        }
      }

      .rightBody {
        width: 193px;
        height: 220px;
        background: url("@/assets/zhuanti/flowPathRight.png") no-repeat;
        position: absolute;
        right: 28px;
        top: 0px;
        cursor: pointer;
        z-index: 2;

        .topTitle {
          font-size: 18px;
          color: #e76c2d;
          font-weight: bold;
          margin-top: 7px;
          margin-left: 7px;
          cursor: pointer;
        }

        .centerTitle {
          margin-top: 14px;
          margin-left: 19px;
          cursor: pointer;

          li {
            cursor: pointer;
            font-size: 14px;
            color: #fff;
            line-height: 22px;
            background: url("@/assets/image/ico32.png") no-repeat left center;
            padding-left: 14px;
          }
        }

        .bottomTitle {
          cursor: pointer;
          font-size: 14px;
          color: #e41539;
          margin-top: 34px;
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }
}
</style>