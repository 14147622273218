<template>
    <div class="teacher-hot">
        <use-sub-title :title="props.title" subTitle="任何考试问题 人工免费解答 了解实时讯息"></use-sub-title>
        <div class="center">
            <div class="leftBody">
                <div class="header">
                    <img src="@/assets/image/ico22.png" alt="">
                    <p>各省市考试信息查询入口</p>
                    <img src="@/assets/image/ico23.png" alt="">
                </div>
                <p class="English-b">Provincial and municipal examination information query portal</p>
                <ul class="provinceList">
                    <li v-for="(item, index) in provinceList" :key="index" @click="useCustomerService">
                        {{ item }}
                    </li>
                </ul>
                <ul class="bottomList">
                    <li @click="useCustomerService">报名入口</li>
                    <li @click="useCustomerService">报名条件</li>
                </ul>
            </div>
            <div class="rightBody">
                <div class="top">
                    <p class="hotTitle">
                        — 考试热点问题咨询 —
                    </p>
                    <div class="bottomInput">
                        <input type="text" :placeholder="name">
                        <span @click="useCustomerService">搜索</span>
                    </div>
                </div>
                <ul class="questionList">
                    <li v-for="(item, index) in questionList" :key="index" @click="useCustomerService">
                        {{ item }}
                        <span>我要提问</span>
                    </li>
                </ul>
                <ul class="rightBottomList">
                    <li @click="useCustomerService">报名入口</li>
                    <li @click="useCustomerService">报名条件</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useCustomerService } from '@/api/common.js'
import { reactive, computed } from "vue";
import { useRoute } from 'vue-router';


const props = defineProps({
    title: {
        default: '标题',
    }
})
const route = useRoute();
const CODE = {
    country: "国考公务员",
    province: "省考公务员",
    career: "事业编考试",
    // troops: "军队文职",
  };
const name = computed(() => {
    if (route.name == 'country') {
        return `2025年${CODE[route.name]}考试热点问题`;
    }else {
        return `2024年${CODE[route.name]}考试热点问题`;
    }
});

const provinceList = reactive(['北京', '上海', '天津', '重庆', '黑龙江', '吉林', '宁夏', '陕西', '山西', '山东', '河北', '河南', '广东', '广西', '四川', '云南', '贵州', '福建', '辽宁', '内蒙古', '新疆', '甘肃', '浙江', '海南', '湖北', '湖南', '安徽', '江苏', '更多']);

const questionList = reactive([`${route.name == 'country' ? '2025' : '2024'}年${CODE[route.name]}报考政策调整内容？`, `${CODE[route.name]}报考条件？`, `${CODE[route.name]}考试需要提供哪些材料？`, '往年考试题库有哪些？', '面试和笔试怎么备考？'])

</script>

<style lang="scss" scoped>
.teacher-hot {
    background: url('@/assets/image/teacherBgc.jpg');
    height: 700px;

    .center {
        width: 1200px;
        height: 455px;
        background: #f16a5d;
        margin: 10px auto 0 auto;
        display: flex;

        .leftBody {
            width: 695px;

            .header {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 30px;

                p {
                    color: white;
                    margin: 0 15px;
                    font-size: 24px;
                }

                img {
                    width: 146px;
                    height: 16px;
                    margin-top: 5px;
                }
            }

            .English-b {
                font-size: 12px;
                color: #fff;
                font-weight: normal;
                margin-top: 5px;
                text-align: center;
            }

            .provinceList {
                width: 612px;
                margin: 15px auto 0 auto;
                overflow: hidden;
                display: flex;
                flex-wrap: wrap;

                li {
                    width: 90px;
                    height: 38px;
                    line-height: 38px;
                    border: 1px solid white;
                    text-align: center;
                    cursor: pointer;
                    margin: 0 10px 15px 0;
                    font-size: 16px;
                    color: #fff;
                    transition: all 0.3s;
                }

                li:hover {
                    background: #fff;
                    color: #f16a5d;
                }
            }

            .bottomList {
                display: flex;
                justify-content: center;
                margin-top: 10px;

                li {
                    width: 120px;
                    height: 38px;
                    line-height: 38px;
                    text-align: center;
                    cursor: pointer;
                    border: solid 1px #fff;
                    border-radius: 50px;
                    margin: 0 13px;
                    color: #fff;
                }

                li:hover {
                    background: #fff;
                    color: #e76c2d;
                }
            }
        }

        .rightBody {
            width: 505px;
            height: 475px;
            background-color: #fff;
            margin-top: -34px;

            .top {
                width: 446px;
                height: 179px;
                background: url('@/assets/image/ico24.png') no-repeat center center;
                background-size: 446px 179px;
                margin: 20px auto 0 auto;

                .hotTitle {
                    // display: inline-block;
                    font-size: 24px;
                    color: #fff;
                    padding-top: 35px;
                    text-align: center;
                }
            }

            .bottomInput {
                position: relative;

                input {
                    width: 350px;
                    height: 42px;
                    font-size: 14px;
                    color: #6f6f6f;
                    border: none;
                    background: #fff;
                    padding-left: 25px;
                    border-radius: 21px;
                    position: absolute;
                    left: 28px;
                    top: 26px;
                }

                span {
                    position: absolute;
                    right: 46px;
                    top: 29px;
                    width: 65px;
                    height: 35px;
                    line-height: 33px;
                    display: inline-block;
                    padding-right: 15px;
                    border-radius: 50px;
                    text-align: right;
                    color: #fff;
                    font-size: 14px;
                    background: url('@/assets/image/search1.png') no-repeat 15px;
                    background-size: 14px 15px;
                    background-color: #e76c2d;
                    cursor: pointer;
                }
            }

            .questionList {
                width: 450px;
                height: 190px;
                margin: 20px 0 0 29px;
                // background: red;

                li {
                    padding-left: 15px;
                    height: 37px;
                    line-height: 37px;
                    color: #5a5a5a;
                    cursor: pointer;
                    border-bottom: 1px dashed #dedede;
                    background: url('@/assets/image/ico33.png') no-repeat left 16px;

                    span {
                        float: right;
                        width: 65px;
                        height: 20px;
                        line-height: 20px;
                        text-align: center;
                        font-size: 12px;
                        color: #e76c2d;
                        border: solid 1px #e76c2d;
                        border-radius: 50px;
                        margin-top: 7px;
                        transition: all 0.3s;
                        cursor: pointer;
                    }
                }

                li:hover {
                    color: #e76c2d;

                    span {
                        background: #e76c2d;
                        color: #fff;
                    }
                }
            }

            .rightBottomList {
                display: flex;
                justify-content: center;
                margin-top: 10px;

                li {
                    width: 120px;
                    height: 38px;
                    line-height: 38px;
                    text-align: center;
                    cursor: pointer;
                    border: solid 1px #e76c2d;
                    border-radius: 50px;
                    margin: 0 13px;
                    color: #e76c2d;
                }

                li:hover {
                    background: #e76c2d;
                    color: #fff;
                }
            }
        }
    }
}</style>