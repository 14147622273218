export const testTableHeader = [
    {
        label: '考试名称',
        key: 'name',
        width: '110'
    },
    {
        label: '笔试时间',
        key: 'penTime',
        width: '130'
    },
    {
        label: '考试大纲',
        key: 'outline',
        width: '100'
    },
    {
        label: '报名时间及入口',
        key: 'enrollTime',
        width: '180'
    },
    {
        label: '公共发布',
        key: 'publish',
        width: '140'
    },
    {
        label: '缴费时间',
        key: 'payTime',
        width: '145'
    },
    {
        label: '合格标准',
        key: 'qualified',
    }
]

export const testTableData = [
    {
        name: '国家公务员',
        penTime: '2025.1.07-08',
        outline: '查看',
        enrollTime: '2022.10.25-11.03 入口',
        publish: '入口',
        payTime: '查看',
        qualified: '查看'
    },
    {
        name: '山西公务员',
        penTime: '2025.02.25-26',
        outline: '查看',
        enrollTime: '2025.01.03-10 入口',
        publish: '2025.01.03 入口',
        payTime: '2025.01.13',
        qualified: '查看'
    },
    {
        name: '上海公务员',
        penTime: '2022.12.11',
        outline: '查看',
        enrollTime: '2022.11.04-10 入口',
        publish: '2022.11.03 入口',
        payTime: '2022.11.04-11',
        qualified: '查看'
    },
    {
        name: '黑龙江公务员',
        penTime: '2025.02.25-26',
        outline: '查看',
        enrollTime: '2025.01.29-2.2 入口',
        publish: '2025.01.17 入口',
        payTime: '2025.01.29-02.03',
        qualified: '查看'
    },
    {
        name: '吉林公务员',
        penTime: '2025.02.25-26',
        outline: '查看',
        enrollTime: '2025.01.29-02.02 入口',
        publish: '2025.01.17 入口',
        payTime: '2025.01.29-02.02',
        qualified: '查看'
    },
    {
        name: '新疆公务员',
        penTime: '2025.02.25-26',
        outline: '查看',
        enrollTime: '2025.01.28-02.03 入口',
        publish: '2025.01.20 入口',
        payTime: '2025.01.29-02.04',
        qualified: '查看'
    },
    {
        name: '河北公务员',
        penTime: '2025.02.25-26',
        outline: '查看',
        enrollTime: '2025.01.30-02.03 入口',
        publish: '2025.01.28 入口',
        payTime: '2025.02.04截止',
        qualified: '查看'
    },
    {
        name: '江西公务员',
        penTime: '2025.02.25-26',
        outline: '查看',
        enrollTime: '2025.01.11-17 入口',
        publish: '2025.01.09 入口',
        payTime: '2025.01.11-17',
        qualified: '查看'
    },
]