<template>
  <div class="home">
    <!-- banner -->
    <el-carousel height="900px">
      <el-carousel-item>
        <img src="@/assets/image/home-banner1.png" alt="" class="homeBanner" @click="useCustomerService" />
      </el-carousel-item>
      <!-- <el-carousel-item>
        <img src="@/assets/image/home-banner.png" alt="" class="homeBanner"
          @click="useCustomerService" />
      </el-carousel-item> -->
      <el-carousel-item>
        <img src="@/assets/image/home-banner2.png" alt="" class="homeBanner"
          @click="useCustomerService" />
      </el-carousel-item>
    </el-carousel>
    
    <div class="centerBody">
      <!-- <img @click="useCustomerService" src="../assets/home/one.png" alt="" /> -->
      <!-- 报考指南 -->
      <career-body></career-body>
      <!-- 公务员考试部分 -->
      <image-text class="imageText" :image-text-ogj="publicAffairs"></image-text>
       <!-- 军队文职 -->
       <!-- <image-text class="imageText" :image-text-ogj="militaryCivilian"></image-text> -->
      <!-- 事业/教师编 -->
      <image-text class="imageText" :image-text-ogj="causeOrTeach"></image-text>
      <!-- 报考时间一览 -->
      <test-time class="test-time" :test-table-data="testTableData" :test-table-header="testTableHeader"
        @cellBtn="cellBtn"></test-time>
      <!-- 高端通关 -->
      <passage-vue></passage-vue>
    </div>
  </div>
</template>

<script setup>
import { publicAffairs, causeOrTeach } from '@/api/imageText.js'
import { testTableHeader, testTableData } from '@/api/testTime.js'

import { useCustomerService } from '@/api/common.js'


import imageText from '@/components/homeView/imageText.vue'
import careerBody from '@/components/homeView/careerBody.vue'
import testTime from '@/components/homeView/testTime.vue'
import passageVue from '@/components/homeView/passageVue.vue'


// 点击每一个表格抛出的事件
const cellBtn = (column) => {
  const is_header = ['outline', 'publish', 'payTime', 'qualified']
  if (is_header.includes(column.property)) {
    useCustomerService();
  }
};

</script>


<style scoped lang="scss">
img {
  cursor: pointer;
}

.centerBody {
  width: 1220px;
  margin-left: 50%;
  // height: 3400px;
  transform: translate(-50%);

  .imageText,
  .test-time {
    margin-left: 12px;
  }
}
</style>