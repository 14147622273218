export const download = {
    country: [
        {
            title: '学习经验',
            navList: [
                '申论历年必考内容',
                '申论技巧：善用核心词句',
                '两个月上岸学习方法',
                '行测历年必考内容',
                '面试技巧大神总结重点',
            ]
        },
        {
            title: '精品视频',
            navList: [
                '申论必考40分内容视频',
                '行测大神精讲课程下载',
                '申论技巧30节视频必看下载',
                '行测常识判断汇总3节课',
                '面试现场动画图解视频',
            ]
        },
        {
            title: '真题解析',
            navList: [
                '近三年《行政能力测试》真题及答案',
                '近三年《申论》真题及答案',
                '近三年言语理解与表达真题及答案',
                '近三年行测技巧真题及答案',
                '近三年申论技巧真题及答案',
            ]
        },
        {
            title: '最新教材',
            navList: [
                '《行政能力测试》全新版',
                '《申论》全新版',
                '《公考复习经验及答题方法蓝皮书》全',
                '《行测专项训练1000题》全新版',
                '《申论高分训练》全新版',
            ]
        },
        {
            title: '模拟试题',
            navList: [
                '全新整理《申论》模拟题',
                '全新整理《行测专项训练》模拟题',
                '全新整理《行政能力测试》模拟题',
                '强化知识点模拟题（全科）',
                '《特邀老师模拟试题》20套注册下载',
            ]
        },
        {
            title: '辅导资料',
            navList: [
                '2025年《行政能力测试》冲刺卷',
                '2025年《申论》冲刺卷',
                '2025年《公考高分训练》冲刺卷',
                '2025年公考冲刺卷（单科）',
                '2025年公考冲刺卷（全科）',
            ]
        },
    ],
    province: [
        {
            title: '学习经验',
            navList: [
                '申论历年必考内容',
                '申论技巧：善用核心词句',
                '两个月上岸学习方法',
                '行测历年必考内容',
                '面试技巧大神总结重点',
            ]
        },
        {
            title: '精品视频',
            navList: [
                '申论必考40分内容视频',
                '行测大神精讲课程下载',
                '申论技巧30节视频必看下载',
                '行测常识判断汇总3节课',
                '面试现场动画图解视频',
            ]
        },
        {
            title: '真题解析',
            navList: [
                '近三年《行政能力测试》真题及答案',
                '近三年《申论》真题及答案',
                '近三年言语理解与表达真题及答案',
                '近三年行测技巧真题及答案',
                '近三年申论技巧真题及答案',
            ]
        },
        {
            title: '最新教材',
            navList: [
                '《行政能力测试》全新版',
                '《申论》全新版',
                '《公考复习经验及答题方法蓝皮书》全',
                '《行测专项训练1000题》全新版',
                '《申论高分训练》全新版',
            ]
        },
        {
            title: '模拟试题',
            navList: [
                '全新整理《申论》模拟题',
                '全新整理《行测专项训练》模拟题',
                '全新整理《行政能力测试》模拟题',
                '强化知识点模拟题（全科）',
                '《特邀老师模拟试题》20套注册下载',
            ]
        },
        {
            title: '辅导资料',
            navList: [
                '2025年《行政能力测试》冲刺卷',
                '2025年《申论》冲刺卷',
                '2025年《公考高分训练》冲刺卷',
                '2025年公考冲刺卷（单科）',
                '2025年公考冲刺卷（全科）',
            ]
        },
    ],
    career: [
        {
            title: '学习经验',
            navList: [
                '公基历年必考内容',
                '公基技巧：善用核心词句',
                '两个月上岸学习方法',
                '职测历年必考内容',
                '面试技巧大神总结重点',
            ]
        },
        {
            title: '精品视频',
            navList: [
                '职测必考内容视频',
                '公基大神精讲课程下载',
                '综合技巧视频必看下载',
                '综合能力汇总3节课',
                '面试现场动画图解视频',
            ]
        },
        {
            title: '真题解析',
            navList: [
                '近三年《公共基础知识》真题及答案',
                '近三年《职测》真题及答案',
                '近三年综合真题',
                '近三年必考公基真题及答案',
                '近三年数学技巧真题及答案',
            ]
        },
        {
            title: '最新教材',
            navList: [
                '《教育基础与知识》全新版',
                '《公共基础知识》全新版',
                '教师复习经验及答题方法',
                '全版综合专项训练1000题',
                '《职业能力训练》全新版',
            ]
        },
        {
            title: '模拟试题',
            navList: [
                '全新整理《公基》模拟题',
                '全新整理《综合专项训练》',
                '全新整理《教育基础与知识》模拟题',
                '强化知识点模拟题（全科）',
                '《特邀老师模拟试题》20套注册下载',
            ]
        },
        {
            title: '辅导资料',
            navList: [
                '2025年《职测全版》冲刺',
                '2025年《综合》冲刺卷',
                '2025年《职测高分训练》冲',
                '2025年职测冲刺卷(全科)',
                '2025年职测冲刺卷(全科)',
            ]
        },
    ],
    troops: [
        {
            title: '学习经验',
            navList: [
                '公共知识历年必考内容',
                '岗位能力:善用核心词句',
                '两个月上岸学习方法',
                '管理岗位历年必考内容',
                '专业技术岗位大神总结重点',
            ]
        },
        {
            title: '精品视频',
            navList: [
                '公共科目内容视频',
                '专业科目精讲课程下载',
                '言语表达30节视频必看下',
                '岗位能力判断推理汇总3节',
                '专业岗位动画图解视频',
            ]
        },
        {
            title: '真题解析',
            navList: [
                '近三年判断推理真题及答案',
                '近三年《公共知识》真题及答案',
                '近三年《专业》真题及答案',
                '近三年言语理解与表达真题',
                '近三年数量关系真题及答案',
            ]
        },
        {
            title: '最新教材',
            navList: [
                '《公共知识》全新版',
                '《岗位能力》全新版',
                '《文职复习经验及答题方法》全新版',
                '岗位能力专项训练1000题',
                '《专业科目训练》全新版',
            ]
        },
        {
            title: '模拟试题',
            navList: [
                '全新整理《公共知识》模拟题',
                '全新整理《公共科目》',
                '全新整理《公共科目》模拟题',
                '岗位能力知识点模拟题（全科）',
                '《特邀老师模拟试题》20套注册下载',
            ]
        },
        {
            title: '辅导资料',
            navList: [
                '2023年《公共科目测试》冲刺卷',
                '2023年《言语》冲刺卷',
                '2023年军队文职冲刺卷',
                // '2023年《军队文职高分训练》（全',
                '2023年资料分析冲刺卷（全科）',
            ]
        },
    ],
} 